import React, { useRef } from "react";
import { Button } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './Nav.css';
import { Link } from 'react-router-dom';
import textlogo from "./mango-logo.png"

function Nav({campaigns, loggedIn}) {

    const navRef = useRef();

    const showNavBar = () =>{
        navRef.current.classList.toggle("responsive_nav")
    }


    return (
        <div className="main_nav_wrapper">
            <div>
                <img src={textlogo} className="nav-logo"/>
            </div>
            <nav ref={navRef}>
                {/* <Link to='/' style={{textDecoration:"None", color: "black"}}><div className="nav_link" ><strong>Home</strong></div></Link>
                <br/>
                <Link to='/about' style={{textDecoration:"None", color: "black"}}><div className="nav_link" ><strong>About</strong></div></Link>
                <br/>
                <Link to='/contact' style={{textDecoration:"None", color: "black"}}><div className="nav_link" ><strong>Contact</strong></div></Link>
                <br/>
                <Button 
                className="nav_btn nav_close_btn"
                onClick={showNavBar}
                endIcon={<CloseIcon style={{fontSize: 'min(10vw, 48px)', fontWeight:"bold"}}/>} /> */}
                <div className="call-to-action" onClick={() => window.location.href = 'mailto:sean@mangoco.io?subject=I%20Would%20Like%20To%20Get%20a%20Quote&body=%3CPlease%20enter%20your%20project%20details%20here%20and%20any%20relavant%20contact%20information%3E'}>Get Quote</div>
            </nav>  
            <Button 
            className="nav_btn"
            onClick={showNavBar}
            endIcon={<MenuIcon style={{fontSize: 'min(10vw, 48px)', fontWeight:"bold"}}/>} />
        </div>    
    )

};

export default Nav;
