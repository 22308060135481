
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import HomePage from './Pages/HomePage/HomePage';
import AboutPage from './Pages/AboutPage/AboutPage';


function App() {

  return (
      <>
        <Router>
          <Routes>
            <Route path="/about" exact element={<AboutPage />}/>
            <Route path="*" exact element={<HomePage/>}/>
          </Routes>
        </Router>
      </>
  );
}

export default App;
