import React from 'react'
import Nav from '../../Components/Nav/Nav'
import "./AboutPage.css"

function AboutPage() {
  return (
    <div>
        <Nav />
        <div className='rainbow-border'>
            <div className='about-title'>
                <div>
                    <strong>
                        <div className='about-title-text-wrap'>WHO </div>
                        <div className='about-title-text-wrap'>ARE</div>
                        <div className='about-title-text-wrap'>WE?</div>
                    </strong>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutPage