import ParticlesBg from 'particles-bg'
import React, {useState, useEffect} from 'react'
import useWindowDimensions from '../../Hooks/WindowDimensions';
import "./HomePage.css";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Avatar, Button, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper } from '@mui/material';
import textlogo from "./text-logo.png"
import {MouseParallaxChild, MouseParallaxContainer} from "react-parallax-mouse";
import Nav from "../../Components/Nav/Nav"
import ReactTypingEffect from 'react-typing-effect';
import { Box } from '@mui/system';
import CloudIcon from '@mui/icons-material/Cloud';
import PsychologyIcon from '@mui/icons-material/Psychology';
import BrushIcon from '@mui/icons-material/Brush';
import { StarRate } from '@mui/icons-material';

function HomePage() {

  const { height, width } = useWindowDimensions();
  const [desc, setDesc] = useState("")

  const aboutDescriptors = [
    "Overly Caffienated Engineering",
    "Building Silly Ideas to Perfection",
    "Plan, Execute, Build... need more coffee",
    "All Technical Expertise, No Common Sense",
    "Was I supposed to put something here?",
    "Quit enigneering for good, now engineering for evil",
    "The freelancers nobody asked for",
    "We took 3 years of highschool Spanish",
    "Engineering... but with a Minnesotan accent",
    "Our Wordle streak is 68 days and counting",
    "Did you know Mangos originated from India?",
    "Mangos are closely related to cashews",
    "Sorry I'm late, my car had a flat",
    "HELP! I'M STUCK WITH CAPS LOCK",
    "Of course my degree is legitimate...",
    "They won't feed me until I finish writing these",
    "You should take a nap, you deserve it!",
    "I don't feel too good Mr.Stark..."
  ]
  const about_text = aboutDescriptors[Math.floor(Math.random()*aboutDescriptors.length)];

  var config = {
    num: [3, 12],
    rps: 3,
    radius: [Math.min(height, width)/32, Math.min(height, width)/8 ],
    life: [60,60],
    v: [0, 0],
    tha: [-40, 40],
    rotate: [0, 0],
    alpha: [0.6, 0],
    scale: [1, 0.5],
    position: {x: width*(0.05)+Math.min(height, width)/8, y:height+Math.min(height, width)/8, width:width*.9-Math.min(height, width)/8, height: Math.min(height, width)/8}, 
    color: ["random", "#8c6fad", "#04b2a2", "#b3ce00", "#fee300", "#f6b312", "#51bfee", "#e54c3d"],
    cross: "bround", // cross or bround
    random: 2,  // or null,
    g: 0,    // gravity
    f: [0, -0.7], // force
    onParticleUpdate: (ctx, particle) => {
        ctx.beginPath();
        //ctx.rect(particle.p.x, particle.p.y, particle.radius * 2, particle.radius * 2);
        ctx.fillStyle = particle.color+"40";
        ctx.arc(particle.p.x, particle.p.y, particle.radius, 0, 2 * Math.PI);
        ctx.fill();
        ctx.closePath();
    }
  };

  const scrollToContent = () =>{
    window.scrollTo({
      top: height, 
      behavior: 'smooth'
    });
  };

  const Typer = () => {
    return (                            
        <strong className='strong-wrap'>
                <div className='home-about-text'>
                    <ReactTypingEffect  
                    text={aboutDescriptors}
                    speed={100}
                    eraseSpeed={100}
                    typingDelay={2000}
                    cursorRenderer={cursor => <></>}
                    />
                </div>
        </strong>
    )
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show')
        } else{
          entry.target.classList.remove('show')
        }
      })
    })
  
    const hiddenElements = document.querySelectorAll('.hidden')
    hiddenElements.forEach((elem) => {observer.observe(elem)})

  },[])

  return (
    <div >
        <Nav />
        <div className='down-btn-wrap hidden'>
          <IconButton onClick={() => scrollToContent()} className='down-wrap'>
            <ExpandCircleDownIcon  className='down-btn'/>
          </IconButton>
        </div>
        <div className='home-intro-wrap hidden'>
            <MouseParallaxContainer>
                <MouseParallaxChild factorX={0.05} factorY={0} >
                    <ParticlesBg type="custom"config={config} bg={{ height: "100vh", zIndex: -1}} />
                </MouseParallaxChild>
                    <div className='top-home-wrap'>
                        <MouseParallaxChild factorX={-0.02} factorY={-0.02}>
                            <img src={textlogo} className="home-logo"/>
                        </MouseParallaxChild>
                    </div>
            </MouseParallaxContainer>
        </div>
        <div className='home-second-panel'>
        <div className='home_body'>
        <Grid container spacing={2} className="home_grid_container">
          <Grid item xs={12} md={6.5} className="grid-item" style={{textAlign:"center"}}>
            <img  className="home_image" src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/edc3ab78364175.5ca3009cb66a0.gif" />
          </Grid>
          <Grid item xs={12} md={5.5} className="overview_wrapper grid-item">
            <Paper elevation={10} className="how_to home_about">
              <Typer/>
              <p className='overview_text' style={{marginTop:"-2em"}}>Bring the strongest and most advanced tools to your business, personal project or whatever you need. We offer custom built software with easy maintenance and upkeep</p>
              <p className='overview_text'><strong>Custom Solutions to bring you to the next level!</strong></p>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} >
            <Paper elevation={12} className="how_to" style={{marginTop: "3em"}}>
              <p className='overview_text'>
                <strong>What do we do?</strong>
              </p>
              <p className='overview_text'>
                We only offer tech that can make you stand out from your competitors.
              </p>
              <Box style={{maxWidth: "200px", margin:"auto"}}>
                  <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <CloudIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Cloud Computation"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <PsychologyIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Artificial Intelligence"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <BrushIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Web Design"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <StarRate />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="SEO"
                        />
                      </ListItem>
                  </List>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Box className='how_to' style={{paddingBottom:"0.5em", marginBottom: "1em"}}>
              <p className='overview_text' style={{marginTop: "0", paddingTop: "0"}}>
                <strong>Simply click 'get quote' at the top of the page to begin!</strong>
              </p>
            </Box>
          </Grid>
        </Grid>
      </div>
        </div>
    </div>
  )
}

export default HomePage